import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { JobFeature } from "../components/job-components"
import SuccessStory from "../components/success-story"

const SuccessStoryNavvis = ({ data }) => {
  return (
    <SuccessStory
      title="Powering image anonymization on a global scale"
      customerName="NavVis"
      customerLocation="Germany"
      customerDescription="NavVis aims to bridge the gap between the physical and digital worlds through reality capture technology that provides the digital foundation for the world you want to live in. Headquartered in Munich, Germany, and with offices in the United States, the United Kingdom, and China, NavVis serves global customers across the surveying, AEC, automotive, and manufacturing industries."
      customerIndustries="Software, Hardware, Surveying, AEC, Automotive, Manufacturing"
      link="https://navvis.com/"
      logo=""
      style={{
        background: "url(/images/navvis-sample-1.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <h2 className="my-3">Challenge</h2>
      <p>
        <strong>
          NavVis provides a complete mobile mapping solution for capturing, processing, and visualizing point cloud and
          panorama imagery data. The NavVis VLX mobile mapping system is equipped with multiple high-resolution cameras
          that capture HDR images both indoors (e.g., in factories) and outdoors (e.g., in parking lots). These images
          often contain personal data such as faces and license plates.
        </strong>
      </p>
      <p>
        As data privacy laws (GDPR, CCPA, APPI, etc.) have become a pressing concern for NavVis's global customers and
        resellers, and considering the large volume of images captured, the NavVis team had to find a solution to
        automate the process of anonymizing personal data urgently.
      </p>
      <p>
        The NavVis product team ultimately aimed to introduce a feature for NavVis IVION users (NavVis's cloud-based
        post-processing and visualization platform) to automatically blur faces and license plates.
      </p>
      <p>
        Due to a tight product development schedule, building an anonymization solution in-house was not feasible. Many
        3rd party solutions also did not meet the high requirements of NavVis and its diverse range of customers.
      </p>
      <h2 className="my-3">Solution</h2>
      <p>
        The collaboration between NavVis and Celantur dates back to September 2021 when the NavVis Enterprise team first
        used Celantur Cloud to meet the demand for image anonymization from automotive customers.
      </p>
      <p>
        NavVis and its partners have scanned millions of square meters of manufacturing plants for companies like BMW
        Group using its wearable mobile mapping system, NavVis VLX.{" "}
      </p>

      <p>
        During their initial collaboration, Celantur processed and anonymized over 2 million images. However, as
        customer demand and data volume continued to increase, NavVis decided to evaluate whether the Celantur
        anonymization solution could be integrated directly into their cloud infrastructure.
      </p>

      <figure className="figure">
        <Img fluid={data.navvisSample1.childImageSharp.fluid} className="rounded my-3" />
        <figcaption className="figure-caption">Anonymized pedestrians crossing a road. © NavVis GmbH</figcaption>
      </figure>

      <p>
        This integration aimed to reduce the costs of anonymization and improve the delivery time of NavVis's digital
        twin projects.
      </p>

      <p>
        Celantur's solution proved to meet the necessary NavVis performance and quality requirements. In addition,
        NavVis acknowledges Celantur’s support in enhancing operational efficiency and implementing automation measures.
      </p>

      <p>
        After successfully integrating our solution into their AWS cloud infrastructure, NavVis launched the automated
        image blurring for all NavVis IVION users in April 2023, taking a further step in their commitment to putting
        security and privacy at the heart of their products and services.
      </p>

      <figure className="figure">
        <blockquote className="blockquote">
          <cite>
            "Our customers operate all over the globe and require solutions that meet the data privacy requirements that
            customers have come to expect in the industry. Celantur has enabled us to roll out a global solution, which
            we can expand and adapt according to our customers' needs. We are confident that they are the partner with
            whom we will be able to tackle scenarios we need to meet, no matter how complex."
          </cite>
        </blockquote>
        <figcaption className="figure-caption"> - Georg Schroth | CTO and Co-founder at NavVis</figcaption>
      </figure>

      <h2 className="my-3">Outcome</h2>
      <JobFeature>
        Notable cost and time savings for larger projects and datasets compared to manual or external workflows for
        blurring capability.
      </JobFeature>
      <JobFeature>Up to 50% time was saved by using this fully integrated and automated solution.</JobFeature>
      <JobFeature>
        The use of person blurring feature led to significant speed improvement in the processing workflows (up to
        50-60%).
      </JobFeature>
      
      <figure className="figure">
        <Img fluid={data.navvisSample2.childImageSharp.fluid} className="rounded my-3" />
        <figcaption className="figure-caption">Anonymized pedestrians on a bridge. © NavVis GmbH</figcaption>
      </figure>
    </SuccessStory>
  )
}

export default SuccessStoryNavvis

export const query = graphql`
  query {
    navvisSample1: file(relativePath: { eq: "navvis-sample-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    navvisSample2: file(relativePath: { eq: "navvis-sample-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
